import styled from 'styled-components';

import {mainButtonSetup, buttonType} from './setup/setupBtn';

const Btn = styled.button`
        ${mainButtonSetup};
        ${({color}) => buttonType(color)};

        ${({bold}) => {

            if (!bold) return ``;
            else return `
                font-weight: 600;
            `;
        }}
    `;
export default Btn;
