import React from "react";
import styled from "styled-components";

const ProgressBarContainer = styled.div`
    --progress-border: var(--primary);
    --progress-bg: none;
    --progress-bar: var(--primary);

    progress{
        margin-top: 4px;
    }
    progress[value] {
        /* Reset the default appearance */
        -webkit-appearance: none;
        appearance: none;
        width: 250px;
        height: 20px;
        -webkit-box-shadow: inset 0 0 0px 9999px var(--progress-bg);
        border-radius: 4px;
        border: 2px solid var(--progress-border);
        padding : 2px;
        width: 100%;
        height: 24px;
    }
    progress[value]::-webkit-progress-bar {
        background-color: var(--progress-bg);
        border-radius: 4px;
    }
    progress[value]::-moz-progress-bar {
        background-color: var(--progress-bar);
        border-radius: 4px;
    }
 
    progress[value]::-webkit-progress-value {
        background-color: var(--progress-bar);
        border-radius: 4px;
        background-size: 35px 20px, 100% 100%, 100% 100%; 
    }
`;

const ProgressBar = ({value}) => (
    <ProgressBarContainer>
        <progress max="100" value={value}/>
    </ProgressBarContainer>
);

export default ProgressBar;
export {ProgressBarContainer};