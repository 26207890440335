import React from "react";
import styled from "styled-components";
import {Field, ErrorMessage, useField} from 'formik';
import FormGroup, {FormGroupWrapper} from "../FormGroup";

const InputContainer = styled.div`
    width: 100%;
    border: 1px solid var(--form-broder);
    border-radius: 4px;
    input{ 
        background: none;
        border: none;
        height: 100%;
        width: 100%;
        
        @media only screen and (min-width: 700px) {
            padding: 8px 8px;
            font-size: 18px;
        }
        padding: 6px 12px;
        font-size: 14px;
        font-family: 'OpenSans';
        &::placeholder{
            color: var(--txt-gray);
            font-size: 16px;
            font-weight: 100;
            font-family: 'OpenSans';
        }
        &:focus{
            outline: none;
            &::placeholder{
                color: var(--primary);
            }
        }
    }
`;
const InputErrorTxt = styled.div`
    color: var(--danger);
    font-size: 14px;
`;
const InputWrapper = styled.div`
    ${FormGroupWrapper}{
        margin-bottom: 14px!important;
    }
    ${({validationError}) => {
        if (!validationError) return ``;
        else return `
            ${InputContainer} {
                border-color: var(--danger);
                background: #fff2f2;
                input { 
                    &::placeholder {
                        color: var(--danger);
                    }
                }
            }
        `;
    }}
`;

const Input = ({name, placeholder, as}) => {
    const [,meta,] = useField(name);

    return <InputWrapper validationError={meta.touched && meta.error !== undefined}>
        <FormGroup>
            <InputContainer>
                <Field name={name} placeholder={placeholder} as={as}/>
            </InputContainer>
            <InputErrorTxt>
                <ErrorMessage name={name} />
            </InputErrorTxt>
        </FormGroup>
    </InputWrapper>
};

export default Input;
export {InputContainer, InputErrorTxt, InputWrapper};
