import styled from "styled-components";
import {Check, Edit} from '@styled-icons/material';
import TimeLineStatus from "./TimelineStatus";


const TimelineOptionWrapper = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    font-weight: 100;
    &:not(:last-of-type){
        margin-bottom: var(--timeline-option-margin);
        @media only screen and (max-width: 700px) {
            margin-bottom: 0;
        }
    }
    &:not(:last-of-type){
        &::before{
            content: '';
            display: block;
            background: none;
            border: 1px dashed white;
            position: absolute;
            @media only screen and (max-width: 700px) {
                left: var(--timeline-status-size);
                height: 0px;
                width: calc(var(--timeline-option-margin) - 2px);
            }
            @media only screen and (min-width: 700px) {
                left: calc((var(--timeline-status-size) / 2));
                bottom: calc(0px - var(--timeline-option-margin));
                width: 0px;
                height: calc(var(--timeline-option-margin) - 2px);
            }
        }
    }
    
    font-size: var(--txt-sm);
    
    ${({status, navigate}) => {
        var conditionalStyle = ``;

        if (status === 'edit') {
            conditionalStyle += `
            color: var(--blank);
            `;
        } else if (status === 'complete') {
            conditionalStyle += `
                color: var(--warning);\
                &::before{
                    border: 1px solid var(--warning)!important;
                }
            `;
        } else if (status === 'edit-complete') {
            conditionalStyle += `
                color: var(--warning);\
                &::before{
                    border: 1px solid var(--warning)!important;
                }
            `;
        } else {
            conditionalStyle += `
                opacity: 0.6;
            `;
        }

        if (navigate)
            conditionalStyle += `
                cursor: pointer;
                transition: 0.6s;
                &:hover{
                    opacity: 0.6;
                }
            `;

        return conditionalStyle;
    }}
`;

const TimeLineTxt = styled.div`
    @media only screen and (max-width: 700px) {
        display: none;
    }
`;

const TimelineOption = ({children, status, navigate, onClick}) => (
    <TimelineOptionWrapper status={status} navigate={navigate} onClick={onClick}>
        <TimeLineStatus status={status}>
        {status==='complete' && <Check />}
        {status==='edit' && <Edit />}
        {status==='edit-complete' && <Edit />}
        </TimeLineStatus>
       <TimeLineTxt>{children}</TimeLineTxt>
    </TimelineOptionWrapper>
);


export default TimelineOption;
export {TimelineOptionWrapper};

