import styled from "styled-components";
const Title = styled.h1`
    margin: 0;
    color: var(--primary);
    font-weight: 900;
    @media only screen and (min-width: 700px) {
        font-size: var(--txt-xxl);
        margin-bottom: var(--space-md);
    }
    font-size: var(--txt-rl);
    margin-bottom: -8px;
`;
const SubTitle = styled.h1`
    margin: 0;
    margin-bottom: var(--space-md);
    color: var(--primary);
    font-weight: 900;
    @media only screen and (min-width: 700px) {
        font-size: var(--txt-lg);
    }
    font-size: var(--txt-rl);
`;
const Description = styled.div`
    margin: 0;
    margin-bottom: var(--space-rl);
    color: var(--txt-gray);
    font-weight: 100;
    @media only screen and (min-width: 700px) {
        font-size: var(--txt-rl);
    }
    font-size: var(--txt-sm);
`;
const Alert = styled.div`
    padding: 10px;
    color: var(--warning);
    border: 1px solid var(--warning);
    @media only screen and (min-width: 700px) {
        margin-bottom: var(--space-rl);
    }
    font-size: var(--txt-sm);
`;

export {Title, SubTitle, Description, Alert};
