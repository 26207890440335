import {useState, useEffect} from 'react';
import axios from 'axios';

export function useQuestions(accessGuid) {
    const [questions, setQuestions] = useState(null);

    useEffect(() => {
        if (!accessGuid) return;

        axios.get(`/api/subsidiewijzer/sw-questions/${accessGuid}`)
            .then(response => {
                setQuestions(response.data);
            });
    }, [accessGuid]);

    return questions;
}