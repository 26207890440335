import React from "react";
import styled from 'styled-components';
import { SubTitle } from "../elements/Txt/Txt";

const Css = styled.div`${({css}) => css}`;

const RoadMapChart = ({html, css}) => 
    <Css css={css}>
        <SubTitle>Gepersonaliseerde Subsidie Roadmap</SubTitle>
        <div dangerouslySetInnerHTML={{ __html: html }} />
        <br></br>
    </Css>

export default RoadMapChart;
