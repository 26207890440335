import {useState, useEffect} from 'react';
import _ from 'lodash';
import axios from 'axios';

function loadCompany(token) {
        return axios.get(`/api/subsidiewijzer/companies`, {
            params: { token }})
            .then(response => response.data);
}

export function useCompany(token) {
    var [ res, setRes ] = useState({
        loading: true,
        questionAnswers: null,
        company: null,
    });

    useEffect(() => {
        loadCompany(token).then(res => {
            setRes({
                loading: false,
                questionAnswers: convertPrefill(res.questionsAnswers),
                company: res.company,
            });
        });
    }, [token]);

    if(!token) return {
        loading: false,
        questionAnswers: null
    };

    return res;
} 

// translate the server response for the prefilled questions, into the format we use in the frontend
export function convertPrefill(questionAnswers) {
    if(questionAnswers == null) return {};

    var res = {};
    _.map(questionAnswers, qa => {
        res[qa.swQuestionId] = _.map(qa.answers, a => a.swAnswerId);
    });
    return res;
}
