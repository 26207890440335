import styled from "styled-components";

const TopbarWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    background: var(--blank);
    @media only screen and (min-width: 700px) {
        border-bottom: 1px solid #DDE2ED;
        padding: var(--topbar-padding);
    }
    padding: 12px var(--space-lg) 0;
`;

const TopbarTitle = styled.div `
    display: flex;
    align-items: center;
    h1{
        @media only screen and (min-width: 700px) {
            font-size: var(--txt-xl);
        }
        @media only screen and (max-width: 700px) {
            font-weight: 900;
            font-size: var(--txt-rl);
        }
    }
`;

const Topbar = () => (
    <TopbarWrapper>
        <TopbarTitle>
            <h1>Subsidiewijzer</h1>
        </TopbarTitle>
    </TopbarWrapper>
)

export default Topbar;
export {TopbarWrapper};