import React from 'react';
import MainContent from '../../shared-components/grid/MainContent/MainContent';
import {Title, Description, Alert} from '../../shared-components/elements/Txt/Txt';
import Btn from '../../shared-components/elements/Btn/Btn';

export default function QuestionnaireWelcome({tenant, company, onStart}) {
    return (
        <MainContent>
            <Title>Welkom in de subsidiewijzer!</Title>
            <Description><div dangerouslySetInnerHTML={{ __html: tenant.welcomePageText }}></div></Description>

            {company && <Alert>Deze vragenlijst is vooraf ingevuld voor {company.name}</Alert>}
            
            <Btn color='primary' type="button" onClick={onStart}>Beginnen</Btn>
        </MainContent>
    );
}
