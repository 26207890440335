import styled from "styled-components";

const FormGroupWrapper =  styled.div`
    margin-bottom: var(--space-xl);
    >label{
        margin-bottom: var(--space-sm);
        display: inline-block;
        color: var(--txt-gray);
        font-weight: 700;
    }
`;

const FormGroup = ({children, label}) => (
    <FormGroupWrapper>
        {label && <label>{label}</label>}
        {children}
    </FormGroupWrapper>
);

export default FormGroup;
export {FormGroupWrapper};