import styled from "styled-components";

const Question = styled.h1`
    margin: 0;
    margin-bottom: var(--space-rl);
    color: var(--primary);
    font-weight: 400;
    @media only screen and (min-width: 700px) {
        font-size: var(--txt-xl);
        margin-top: 0;
    }
    font-size: var(--txt-rl);
    margin-top: 8px;
`;
const Subsidy = styled.h1`
    margin: 0;
    margin-bottom: var(--space-rl);
    color: var(--primary);
    font-weight: 400;
    @media only screen and (min-width: 700px) {
        font-size: var(--txt-xl);
    }
    font-size: var(--txt-rl);
`;

export {Question, Subsidy};