import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import MainContent from '../../shared-components/grid/MainContent/MainContent';
import {Title, Description} from '../../shared-components/elements/Txt/Txt';
import Btn from '../../shared-components/elements/Btn/Btn';
import { Question} from '../../shared-components/elements/Txt/Question';

import Card, {CardWrapper} from '../../shared-components/elements/Card/Card';
import axios from 'axios';
import parse from 'html-react-parser';

import ProgressBar from '../../shared-components/Charts/ProgressBar';
import Roadmap from '../../shared-components/Charts/RoadMap';
import { BtnLink } from '../../shared-components/elements/Btn/BtnLink';

function ResultPageText({tenant}) {
    if(tenant.resultPageText) {
        return <Description><div dangerouslySetInnerHTML={{ __html: tenant.resultPageText }}></div></Description>
    } else {
        return <Description>Hieronder staan de resultaten van de ingevulde Subsidiewijzer. De resultaten geven een indicatie van de relevante subsidies en de tijdslijn waarop deze zich bevinden. Uiteraard is het herkennen van subsidies belangrijk, maar het verzilveren ervan daar gaat het om. Leap helpt hier graag bij!</Description>;
    }
}

export default function QuestionnaireResult({givenAnswers, tenant, company, companyToken}) {
    const [results, setResults] = useState(null);
    const [resultsSaved, setResultsSaved] = useState(false);

    function reload() {
        axios.post(`/api/subsidiewijzer/sw-results/results`, { questionAnswers: givenAnswers })
            .then(response => setResults(response.data))
            .catch(() => alert('Er ging iets mis'));
    }

    useEffect(reload, [setResults, givenAnswers]);

    const saveResults = () => {
        axios.post(`/api/subsidiewijzer/companies/save`, { questionAnswers: givenAnswers }, { params: {token: companyToken} })
            .then(response => setResultsSaved(true))
            .catch(() => alert('Er ging iets mis'));
    };


    return (
        <MainContent>
            <Title>Resultaten</Title>
            <ResultPageText tenant={tenant} />

            {results ? (
                <Wrapper>
                    <Card>
                        <Roadmap html={results.roadmapHtml} css={results.roadmapCss} />
                    </Card>

                    <Card>
                        {results.matchingSubsidies.map(subsidy => (
                            <InfoContainer key={subsidy.swSubsidyId}>
                                <SubsidyWrapper>
                                    <Card.Title>{subsidy.name}:</Card.Title>
                                    <strong>{subsidy.matchPercentage}% </strong>
                                </SubsidyWrapper>
                                <ProgressBar value={subsidy.matchPercentage} />
                                <InfoDescription>{parse(subsidy.description)}</InfoDescription>
                                {subsidy.informationUrl && <BtnLink  color='warning' href={subsidy.informationUrl} target="_blank" rel="noreferrer">Voor meer informatie klik hier</BtnLink>}
                            </InfoContainer>
                        ))}
                    </Card>

                    {companyToken && <Btn color='warning' type="button" onClick={() => saveResults()}>Resultaten opslaan voor {company.name}</Btn>}
                </Wrapper>
            ) : (
                <span>laden...</span>
            )}

            { companyToken && resultsSaved && <Card><span>De gegevens zijn opgeslagen</span></Card> }
        </MainContent>
    );

}

const InfoContainer = styled.div`
    margin-bottom: 48px;
    &:last-of-type {
        margin-bottom: 0!important;
    }
`;
const InfoDescription = styled (Description)`
    p {
        margin-top: 6px;
    }
`;
const Wrapper = styled.div`
    ${CardWrapper} {
        margin-bottom: 60px;
        .subsidy-legend{
            display: flex;
        }
        .legend-title{
            align-items: top;
        }
    }
`;
const SubsidyWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${Question}{
        margin: 0;
    }
    h1{
        margin-bottom: 2px;
    }
`;

export {SubsidyWrapper};
