import React, {useState} from 'react';
import QuestionnaireSidebar from './QuestionnaireSidebar.js';
import QuestionnaireWelcome from './QuestionnaireWelcome.js';
import QuestionnaireContent from './QuestionnaireContent.js';
import QuestionnaireResult from './QuestionnaireResult.js';
import QuestionnaireContactDetails from './QuestionnaireContactDetails.js';
import QuestionnaireFooter from './QuestionnaireFooter.js';
import UseQuestionsHelper from './useQuestionsHelper.js';
import { CompanyContactInfo } from './CompanyContactInfo.js';
import styled from 'styled-components';
import { MainContentWrapper } from '../../shared-components/grid/MainContent/MainContent.js';


export default function Questionnaire({questions, tenant, prefilledAnswers, company, companyToken}) {
    const [questionsHelper, setQuestionsHelper] = useState(UseQuestionsHelper({
        allQuestions: questions, 
        givenAnswers: prefilledAnswers,
    }));

    const [state, setState] = useState('welcome');

    const next = () => {
        var res = questionsHelper.next();
        setQuestionsHelper(res);
    };

    const back = () => {
        var res = questionsHelper.back();
        setQuestionsHelper(res);
    };

    const setAnswers = (a) => {
        var res = questionsHelper.setAnswer(a);
        setQuestionsHelper(res);
        setState('questions');
    };

    const setQuestion = (swQuestionId) => {
        var res = questionsHelper.setQuestion(swQuestionId);
        setQuestionsHelper(res);
    };

    const showContactDetails = () => {
        const res = questionsHelper.setQuestion(null);
        setQuestionsHelper(res);
        setState('contact-details');
    };

    const showResults = () => {
        var res = questionsHelper.setQuestion(null);
        setQuestionsHelper(res);
        setState('done');
    };


    if (state === 'welcome')
        return (
        <Wrapper>
            <div>
                <QuestionnaireSidebar tenant={tenant} questions={[]} />
                <QuestionnaireWelcome tenant={tenant} onStart={() => setState('questions')} company={company} />
            </div>
            <ContactContainer><CompanyContactInfo tenant={tenant} /> </ContactContainer>
        </Wrapper>)
    else
        return (
            <Wrapper>
                <div>
                    <QuestionnaireSidebar 
                        tenant={tenant} 
                        questions={questionsHelper.activeQuestions} 
                        currentQuestion={questionsHelper.currentQuestion} 
                        givenAnswers={questionsHelper.givenAnswers}
                        setQuestion={setQuestion}
                        questionnaireState={state}
                        goToResults={showResults} />

                    {questionsHelper.currentQuestion ?
                        <>
                            <QuestionnaireContent
                                currentQuestion={questionsHelper.currentQuestion}
                                currentQuestionNumber={questionsHelper.currentQuestionNumber}
                                answers={questionsHelper.currentAnswers}
                                setAnswers={setAnswers}
                                company={company}
                            />
                            <QuestionnaireFooter
                                canBack={questionsHelper.canBack}
                                back={back}
                                canNext={questionsHelper.canNext}
                                next={next}
                                companyToken={companyToken}
                                showContactDetails={showContactDetails}
                                showResults={showResults}
                            />
                        </>
                    : state === 'contact-details' ?
                        <>
                            <QuestionnaireContactDetails
                                tenant={tenant}
                                givenAnswers={questionsHelper.givenAnswers}
                                showResults={showResults}
                            />
                        </>
                    : state === 'done' ?
                        <>
                            <QuestionnaireResult
                                tenant={tenant}
                                givenAnswers={questionsHelper.givenAnswers}
                                company={company}
                                companyToken={companyToken}
                            />
                        </>
                    :
                        <></>
                    }
                </div>
                <ContactContainer><CompanyContactInfo tenant={tenant} /> </ContactContainer>
            </Wrapper>)
}

const ContactContainer = styled(MainContentWrapper)`
    padding-top: 12px;
    padding-bottom: 24px;
    background: #f4f4f4;
    * {
        color: var(--primary);
    }
    @media only screen and (min-width: 700px) {
        display: none;
    }
`;

const Wrapper = styled.div`
    @media only screen and (max-width: 700px) {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        min-height: 100vh;
    }
`;
