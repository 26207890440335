const mainButtonSetup = ({lineHeight, fontSize, padding, borderRadius, display, margin}) => `
    line-height:  ${lineHeight || '1.6'};
    font-size: ${fontSize || '14px'};
    padding: ${padding || '4px 12px'};
    border-radius: ${borderRadius || '2px'};
    display: ${display || 'inline-block'};
    margin: ${margin || '0px'};
    font-weight: 100;
    text-decoration: none;
    cursor: pointer;
    &:hover{
        transition: .6s;
    }
    &:disabled,
    &[disabled]{
        opacity: 0.7;
        cursor: not-allowed!important;
    }
`;
const defaultBtnSetup = () => `
    &:hover{
        opacity: 0.7;
    }
    &:focus{
        opacity: 0.5;
    }

    &:disabled,
    &[disabled]{
        opacity: 0.2;
    }
`;

const defaulButtonColor = ({color}) => `
    background: ${color || 'gray'};;
    border: 1px solid ${color || 'gray'};
    color: white;
`;

const buttonType = (color, round, ghost, link, reverse) => {
    // ---- Ghost buttons ----
    if (ghost)
        return `
       
    `;  
    
    else if (color === 'warning') return `
        ${defaulButtonColor({color: `var(--${color})`})};
        color: var(--primary);
        font-weight: 600;
        ${defaultBtnSetup()};
        
    `;
    else
        return`
            ${defaulButtonColor({color: `var(--${color})`})};
            ${defaultBtnSetup()};  
    `;
    
};
export {mainButtonSetup, defaulButtonColor, buttonType};