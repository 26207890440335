import React from "react";
import styled from "styled-components";
import {Field, ErrorMessage} from 'formik';
import FormGroup, {FormGroupWrapper} from "../FormGroup";

const TextareaContainer = styled.div`
    width: 100%;
    border: 1px solid var(--form-broder);
    border-radius: 4px;
    Textarea{ 
        background: none;
        border: none;
        width: 100%;
        @media only screen and (min-width: 700px) {
            padding: 8px 8px;
            font-size: 18px;
        }
        padding: 6px 12px;
        font-size: 14px;
        resize: none;
        font-family: 'OpenSans';
        font-weight: 300;
        &::placeholder{
            color: var(--txt-gray);
            font-size: 16px;
            font-weight: 100;
            font-family: 'OpenSans';
        }
        &:focus{
            outline: none;
            &::placeholder{
                color: var(--primary);
            }
        }
    }
`;
const TextareaErrorTxt = styled.div`
    color: var(--danger);
`;
const TextareaWrapper = styled.div`
    ${FormGroupWrapper}{
        margin-bottom: 14px!important;
    }
`;

const Textarea = ({name, rows, placeholder}) =>(
    <TextareaWrapper>
        <FormGroup>
            <TextareaContainer>
                <Field rows={rows} name={name} placeholder={placeholder}  as="textarea"/>
            </TextareaContainer>
            <TextareaErrorTxt>
                <ErrorMessage name={name} />
            </TextareaErrorTxt>
        </FormGroup>
    </TextareaWrapper>
);

export default Textarea;
export {TextareaContainer, TextareaErrorTxt, TextareaWrapper};
