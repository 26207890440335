import styled from "styled-components";
import _ from 'lodash';

const SidebarWrapper = styled.div`
    box-sizing: border-box;
    background: var(--primary);
    color: var(--blank);
    padding: var(--sidebar-padding);
    overflow: auto;
    img{
        margin-bottom: 28px;
    }
    @media only screen and (min-width: 700px) {
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        width: var(--sidebar-width);
        padding-bottom: 340px;
    }
    @media only screen and (max-width: 700px) {
        position: sticky;
        top: 0;
        z-index: 100;
    }
`;

const SidebarBottom = styled.div`
    background: var(--primary);
    @media only screen and (min-width: 700px) {
        padding: var(--sidebar-padding);
        position: fixed;
        width: var(--sidebar-width);
        left: 0;
        bottom: 0;
        margin-top: 12px;
    }
    margin-top: 40px;

    @media only screen and (max-width: 700px) {
        display: none;
    }
`;

const Sidebar = ({logo, children}) =>(
    <SidebarWrapper>
        <img src={_.get(logo, 'url')} alt="Bedrijfslogo" style={{maxWidth: 200, maxHeight: 100}} />
        {children}
    </SidebarWrapper>
);

export default Sidebar;
export {SidebarWrapper, SidebarBottom};
Sidebar.Bottom = SidebarBottom;