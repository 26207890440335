import styled from "styled-components";
import TimelineOption from "./TimelineOption";

const TimelineWrapper = styled.div`
    margin-bottom: var(--space-rl);
    @media only screen and (max-width: 700px) {
      display: flex;
      align-items: baseline;
      margin-bottom: 0;
    }
`;


const Timeline = ({children}) => (
  <TimelineWrapper>
        {children}
  </TimelineWrapper>
);


export default Timeline;
export {TimelineWrapper};
Timeline.Option = TimelineOption;



