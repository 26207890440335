import styled from "styled-components";
import Topbar from '../TopBar/TopBar';

const MainContentWrapper = styled.div`
    background: #F3F4F8;
    box-sizing: border-box;
    
    @media only screen and (min-width: 700px) {
        padding: var(--main-content-padding);
    }
    padding: var(--space-sm) var(--space-lg)  var(--space-lg);
`;

const MainContent = ({children}) =>(
    <>
        <Topbar></Topbar>
        <MainContentWrapper>
            {children}
        </MainContentWrapper>
    </>
);

export default MainContent;
export {MainContentWrapper};

