import Icon from "../../../elements/Icon/Icon";
const formControlCheckSelect = ({borderRadius}) => `
    display: flex;
    align-items:center;
    margin-bottom: var(--space-sm);
    input{
        display: none;
    }
    label{
        position: relative;
        vertical-align: middle;
        padding-top: 2px;
        cursor: pointer;
        color: var(--txt-gray);
        font-weight: 100;
        font-size: 14px;
        &:hover{
            color: var(--primary);
        }
        
        &::before{
            content: '';
            display: inline-block;
            width: var(--checkbox-size);
            height: var(--checkbox-size);
            vertical-align: middle;    
            margin-right: var(--space-sm);
            border: 1px solid var(--primary);
            background: none;        
            border-radius:  ${borderRadius || '2px'};
            margin-top: -2px;
        }
        ${Icon}{
            display: none;
            position: absolute;
            color: white;
            top: 0;
            left:0;
        }
    }


    @media only screen and (min-width: 700px) {
        label{
            font-size: 16px;
            ${Icon}{
                left:2px;
            }
        }
    }
    

`;

const formCheckSelection = () => `
    input[type="checkbox"]:checked+label,
    input[type="radio"]:checked+label{
        &::before{
            border: 1px solid var(--primary);
            background: var(--primary);   
        }
        ${Icon}{
            display: block;
        }
        color: var(--primary);
        &:hover{
            opacity: 0.8;
        }
    }
`;

export {formControlCheckSelect, formCheckSelection};