import {Navigate} from 'react-router-dom';
import {useDefaultTenant} from './modules/tenants/tenantsStore';

function DefaultQuestionnaireRedirect() {
    var defaultTenant = useDefaultTenant();
    if(defaultTenant == null) return null;
    return <Navigate to={`/questionnaire/${defaultTenant.accessGuid}`} />;
}

export default DefaultQuestionnaireRedirect;

