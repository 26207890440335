import React, {useEffect} from 'react';
import styled from 'styled-components';
import {OpenInNew} from "@styled-icons/material";
import axios from 'axios';
import {Formik, Form} from 'formik';
import * as Yup from 'yup';

import MainContent from '../../shared-components/grid/MainContent/MainContent';

import Btn from '../../shared-components/elements/Btn/Btn';
import {BtnLink} from '../../shared-components/elements/Btn/BtnLink';
import Card from '../../shared-components/elements/Card/Card';
import Icon from "../../shared-components/elements/Icon/Icon";
import {Description, Title} from '../../shared-components/elements/Txt/Txt';
import {Question} from '../../shared-components/elements/Txt/Question';

import Input, {InputWrapper} from '../../shared-components/form/form-control/Input';
import Textarea, {TextareaWrapper} from '../../shared-components/form/form-control/Textarea';

export default function QuestionnaireResult({givenAnswers, tenant, showResults}) {
    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('Verplicht veld'),
        lastName: Yup.string().required('Verplicht veld'),
        emailAddress: Yup.string().email('Ongeldig email').required('Verplicht veld'),
        phoneNumber: Yup.string().required('Verplicht veld'),
        companyName: Yup.string().required('Verplicht veld'),
    });

    function reload() {
        axios.post(`/api/subsidiewijzer/sw-results/results`, { questionAnswers: givenAnswers })
            .catch(() => alert('Er ging iets mis'));
    }

    useEffect(reload, [givenAnswers]);

    const sendResults = (values) => {
        axios.post(`/api/subsidiewijzer/sw-results/send-by-email`, { questionAnswers: givenAnswers, ...values })
            .then(() => showResults())
            .catch(() => alert('Er ging iets mis'));
    };

    return (
        <MainContent>
            <Title>Bijna klaar!</Title>
            <Description>
            U bent nog één stap verwijderd van een op maat gemaakte Subsidie Roadmap. Zodra u uw contactgegevens in onderstaand formulier heeft ingevuld kunt u de resultaten van de subsidiewijzer inzien. We gebruiken het e-mailadres om de resultaten rechtstreeks naar uw mailbox te sturen, daarmee gaat u ook akkoord met ons privacybeleid.
            </Description>
            <Formik
                onSubmit={sendResults}
                validationSchema={validationSchema}
                initialValues={{
                    swTenantId: tenant.swTenantId,
                    firstName: '',
                    lastName: '',
                    emailAddress: '',
                    phoneNumber: '',
                    companyName: '',
                    additionalQuestions: '',
                }}
            >
                {({isSubmitting}) => (
                    <>
                        <Card>
                            <Form>
                                <Card.Title>Contactgegevens</Card.Title>
                                <FormWrapper>
                                    <Input name="firstName" placeholder="Voornaam"/>
                                    <Input name="lastName" placeholder="Achternaam"/>
                                    <Input name="emailAddress" placeholder="E-mailadres"/>
                                    <Input name="phoneNumber" placeholder="Telefoonnummer"/>
                                    <Input name="companyName" placeholder="Bedrijfsnaam"/>
                                    <Textarea rows='4' name="additionalQuestions" placeholder="Vragen/opmerkingen?"/>
                                </FormWrapper>
                                <Btn color="warning" type="submit" disabled={isSubmitting}>Stuur mij de resultaten</Btn>
                                <ExternalLinkWrapper>
                                    Door op de <b>stuur mij de resultaten</b> knop te klikken gaat u akkoord met ons <ExternalLink href="https://www.leap.nl/privacybeleid-3/" target="_blank" color="ghost">privacybeleid <Icon><OpenInNew/></Icon></ExternalLink>.
                                </ExternalLinkWrapper>
                            </Form>
                        </Card>
                    </>
                )}
            </Formik>
        </MainContent>
    );
}

const FormWrapper = styled.div`
    margin-left: -10px;
    margin-right: -10px;
    display: flex;
    flex-wrap: wrap;
    ${Question}{
        flex: 0 0 100%;
        max-width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 20px;
    }
    ${InputWrapper}{
        padding-left: 10px;
        padding-right: 10px;
        flex: 0 0 100%;
        max-width: 100%;
        @media only screen and (min-width: 1024px) {
            flex: 0 0 50%;
            max-width: 50%;
            &:nth-of-type(1),&:nth-of-type(2),&:nth-of-type(3){
                @media only screen and (min-width: 1440px) {
                    flex: 0 0 33.33%;
                    max-width: 33.33%;
                }
            }
        }       
    }
    ${TextareaWrapper}{
        padding-left: 10px;
        padding-right: 10px;
        flex: 0 0 100%;
        max-width: 100%;   
    }
`;

const ExternalLinkWrapper = styled.div`
    --xlink--font-size: 1rem;
    --xlink--font-weight: 100;

    font-size: var(--xlink--font-size);
    font-weight: var(--xlink--font-weight);
    margin-top: 0.75rem;
`;

const ExternalLink = styled(BtnLink)`
    color: var(--delta);
    font-size: var(--xlink--font-size);
    font-weight: var(--xlink--font-weight);
    text-decoration: underline;
    padding: 0;
`;
