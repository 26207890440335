import styled from "styled-components";
import Btn from "../../elements/Btn/Btn";

const FooterWrapper = styled.div`
    display: flex;
    align-items: center;
    border: none;
    background: #F3F4F8;
    box-sizing: border-box;
    z-index: 200;
    ${Btn} {
        margin-right: 4px;
    }

    @media only screen and (min-width: 700px) {
        height: var(--footer-height);
        padding: var(--footer-padding);
        width: calc(100% - var(--sidebar-width));
        bottom: 0;
    }

    @media only screen and (max-width: 700px) {
        margin-top: -32px;
    }
    padding: 0px var(--space-lg)  var(--space-lg);
`;


const Footer = ({children}) => (
   <FooterWrapper>
       {children}
   </FooterWrapper>
)

export default Footer;
export {FooterWrapper};