import styled from "styled-components";
import {SubTitle} from '../../shared-components/elements/Txt/Txt';
import {BtnLink} from '../../shared-components/elements/Btn/BtnLink';
import parse from 'html-react-parser';
import _ from 'lodash';

const CompanyContactWrapper = styled.div`
    ${BtnLink} {
        @media only screen and (min-width: 700px) {
            padding: 12px 20px;
            font-size: 16px;
            color: var(--primary);
            font-weight: 600;
        }
    }
`;

const ContactInfo = styled.div`
@media only screen and (min-width: 700px) {
    font-size: 15px;
    margin-bottom: var(--space-md);
}
    font-size: 14px;
    margin-bottom: var(--space-sm);
    color: var(--warning);
`;

const CompanyInfoTitle = styled(SubTitle)`
    color: white;
    margin-bottom: 6px;
`;

export const CompanyContactInfo = ({tenant}) => (
    <CompanyContactWrapper>
        <ContactInfo>
            <div>{parse(_.isNull(tenant.contactDetails) ? '' : tenant.contactDetails)}</div>
        </ContactInfo>
        {tenant.contactEmail && <>
            <CompanyInfoTitle>Meer informatie?</CompanyInfoTitle>
            <BtnLink color='warning' href={`mailto:${tenant.contactEmail}?subject=Interesse n.a.v. Subsidiewijzer`}>Stuur een email</BtnLink>
        </>}
    </CompanyContactWrapper>
);