import styled from "styled-components";
import Icon from "../Icon/Icon";

const TimeLineStatusWrapper = styled.div`
    width: var(--timeline-status-size);
    height: var(--timeline-status-size);
    border-radius: 50%;
    position: relative;
    margin-right: var(--space-sm);
    flex-shrink: 0;
    ${Icon}{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        svg{
            padding-bottom: 4px;
        }
    }
    
    @media only screen and (max-width: 700px) {
        margin-right: calc(var(--timeline-option-margin) - 2px);
        ${Icon} svg{
            width: 12px;
            padding-bottom: 2px;
        }
    }
    ${({status}) => {
        if (status === 'edit') {
            return `
                background: none;
                border: 1px solid var(--blank);
            `;
        } else if (status === 'complete') {
            return `
                background: var(--warning);
                border: 1px solid var(--warning);
                color: var(--primary);
            `;
        } else if (status === 'edit-complete') {
            return `
                background: var(--warning);
                border: 1px solid var(--warning);
                color: var(--primary);
            `;
        } else {
            return `
                background: none;
                border: 1px solid var(--blank);
                opacity: 0.6;
            `;
        }
    }}
`;
const TimeLineStatus = ({children, status}) =>( 
   <TimeLineStatusWrapper status={status}>
       <Icon type='sm'>
            {children}
       </Icon>
    </TimeLineStatusWrapper>
);

export default TimeLineStatus;
export {TimeLineStatusWrapper};

