const ShapeVariables = () =>  `

    // -- TXT --
    // ---------
    --txt-xsm:                      12px;
    --txt-sm:                       14px;
    --txt-rl:                       16px;
    --txt-md:                       18px;
    --txt-lg:                       20px;
    --txt-xl:                       24px;
    --txt-xxl:                      28px;

    // -- Grid --
    // ----------
    --sidebar-width:                300px;
    --footer-height:                120px;
    --sidebar-padding:              var(--space-xxl) var(--space-lg);

    @media only screen and (max-width: 1040px) {
        --main-content-left-padding:    calc(var(--sidebar-width) + var(--space-xl));
        --main-content-right-padding:   var(--space-xl);
    }
    
    --main-content-left-padding:    calc(var(--sidebar-width) + var(--space-xxxl));
    --main-content-right-padding:   var(--space-xxxl);

    --main-content-padding:         var(--space-xxl) var(--main-content-right-padding) var(--space-sm) var(--main-content-left-padding);
    --topbar-padding:               var(--space-rl) var(--main-content-right-padding) var(--space-rl) var(--main-content-left-padding);
    --footer-padding:               var(--space-md) var(--main-content-right-padding) var(--space-md) var(--main-content-left-padding);


    // -- TimeLine --
    //---------------
    --timeline-option-margin: var(--space-md);
    @media only screen and (min-width: 700px) {
        --timeline-option-margin: var(--space-xxl);
    }

    
    --timeline-status-size: 20px;
    @media only screen and (min-width: 700px) {
        --timeline-status-size: 24px;
    }



    // -- Form Control --
    // ------------------
    --checkbox-size: 16px;
    @media only screen and (min-width: 700px) {
        --checkbox-size: 20px;
    }
`;


export default ShapeVariables;