import {useState} from 'react';
import QuestionsHelper from './questionnaireHelper.js';

// this is basically a 'wrapper' around the questionnaire helper to change it from the immutable class
// to something that React can work with.
export default function useQuestionsHelper({allQuestions, givenAnswers, currentQuestion}) {
    const [questionsHelper] = useState(new QuestionsHelper(allQuestions, givenAnswers, currentQuestion));

    const wrappedHelper = (helper) => ({
        next: () => {
            helper.next();
            return wrappedHelper(helper);
        },
        back: () => {
            helper.back();
            return wrappedHelper(helper);
        },
        setAnswer: (answer) => {
            helper.setAnswer(answer);
            return wrappedHelper(helper);
        },
        setQuestion: (swQuestionId) => {
            helper.setQuestion(swQuestionId);
            return wrappedHelper(helper);
        },

        allQuestions: helper.allQuestions,
        givenAnswers: helper.givenAnswers,
        currentQuestion: helper.currentQuestion,

        // the ... operator doesn't work. because these are getters
        currentAnswers: helper.currentAnswers,
        currentTags: helper.currentTags,
        activeQuestions: helper.activeQuestions,
        canNext: helper.canNext,
        canBack: helper.canBack,
        currentQuestionNumber: helper.currentQuestionNumber,
        done: helper.done,
    });

    return wrappedHelper(questionsHelper);
}

