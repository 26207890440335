import React from "react";
import styled from "styled-components";
import { SubTitle } from "../Txt/Txt";

const CardWrapper = styled.div`
    border: 1px solid #DDE2ED;
    background: white;
    border-radius: 4px;
    @media only screen and (min-width: 700px) {
        padding: 28px 28px 36px;
        margin: 20px 0;
    }
    padding: 16px;
    margin: 10px 0;
`;

const CardTitle = styled(SubTitle)``;

const Card = ({children}) => (
    <CardWrapper>
        {children}
    </CardWrapper>
);

export default Card;
export {CardWrapper, CardTitle};
Card.Title = CardTitle;