import React from 'react';
import Footer from '../../shared-components/grid/Footer/Footer';
import Btn from '../../shared-components/elements/Btn/Btn';

export default function QuestionnaireFooter({canBack, back, canNext, next, companyToken, showContactDetails, showResults}) {
    return (
        <Footer>
            { canBack && <Btn color='warning' onClick={back}>Vorige</Btn> }
            { canNext && <Btn color='warning' onClick={next}>Volgende</Btn> }

            {!canNext && (
                companyToken ?
                    <Btn color='warning' onClick={showResults}>Resultaten</Btn>
                :
                    <Btn color='warning' onClick={showContactDetails}>Contactgegevens</Btn>
            )}
        </Footer>
    );
}

