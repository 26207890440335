import React from 'react';
import Sidebar from '../../shared-components/grid/Sidebar/Sidebar';
import Timeline from '../../shared-components/elements/Timeline/Timeline';
import _ from 'lodash';
import {questionIsAnswered} from './questionnaireHelper.js';
import { CompanyContactInfo } from './CompanyContactInfo';

export default function QuestionnaireSidebar({questions, givenAnswers, currentQuestion, setQuestion, tenant, questionnaireState, goToResults}) {
    const questionStatus = (question) => {
        var editing = _.get(question, 'order') === _.get(currentQuestion, 'order');
        var complete = questionIsAnswered(question, givenAnswers);
        if (editing && complete)
            return 'edit-complete';
        if (editing)
            return 'edit';
        if (complete)
            return 'complete';
        return '';
    };

    const showContactDetailsOption = ['contact-details', 'done'].includes(questionnaireState);

    const showResultsOption = questionnaireState === 'done';

    return (
        <Sidebar logo={tenant.logo}>
            <Timeline>
                {questions.map((question, idx) => (
                    <Timeline.Option
                        key={question.swQuestionId}
                        status={questionStatus(question)}
                        navigate={question.canNavigateTo}
                        onClick={() => {
                            if (question.canNavigateTo)
                                setQuestion(question.swQuestionId)
                        }}
                    >
                        {idx+1}. {question.title}
                    </Timeline.Option>
                ))}

                {showContactDetailsOption &&
                    <Timeline.Option key={0} status="complete" navigate={false}>
                        Contactgegevens
                    </Timeline.Option>
                }

                {showResultsOption &&
                    <Timeline.Option key={1} status="complete" navigate={true} onClick={goToResults}>
                        Resultaten
                    </Timeline.Option>
                }
            </Timeline>
           <Sidebar.Bottom >
               <CompanyContactInfo tenant={tenant} />
           </Sidebar.Bottom>
        </Sidebar>
    );
}

