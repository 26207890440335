import styled from "styled-components";
import {formControlCheckSelect, formCheckSelection} from "./setup-form-control/setup-form-control.js";
import Icon from "../../elements/Icon/Icon.js";
import { Check } from "@styled-icons/material";

const CheckboxWrapper = styled.div`
    ${formControlCheckSelect({})};
    ${formCheckSelection};
`;

const Checkbox = ({children, id, value, name, checked, onChange}) => (
    <CheckboxWrapper>
        <input type='checkbox' id={id} value={value} name={name} checked={checked} onChange={onChange} />
        <label htmlFor={id}>
            <Icon><Check/></Icon>
            {children}
        </label>
    </CheckboxWrapper>
);

export default Checkbox;
export {CheckboxWrapper};

