import React from 'react';
import {useQuestions} from './questionsStore.js';
import {useTenant} from '../tenants/tenantsStore.js';
import {useCompany} from './companyStore.js';
import {useParams, useSearchParams} from 'react-router-dom';

import Questionnaire from './Questionnaire.js';

export default function QuestionnairePage() {
    const { accessGuid } = useParams();
    const [searchParams] = useSearchParams();
    const companyToken = searchParams.get('token');
    const questions = useQuestions(accessGuid);
    const tenant = useTenant(accessGuid);
    const {
        loading, 
        company, 
        questionAnswers
    } = useCompany(companyToken);

    // data not loaded yet
    if(!tenant || !questions || loading) 
        return <>laden...</>;

    // data loaded, but there are no questions
    if (!questions.length)
        return <>tenant niet geconfigureerd</>;

    return (
        <Questionnaire 
            questions={questions} 
            tenant={tenant} 
            company={company} 
            companyToken={companyToken}
            prefilledAnswers={questionAnswers}
        />
    );
}
