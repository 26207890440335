import styled from "styled-components";
import {formControlCheckSelect, formCheckSelection} from "./setup-form-control/setup-form-control.js";
import Icon from "../../elements/Icon/Icon.js";
import { Check } from "@styled-icons/material";

const RadioWrapper = styled.div`
    ${formControlCheckSelect({
        borderRadius: '50%',
    })};
    ${formCheckSelection};
`;

const Radio = ({children, id, value, name, checked, onChange}) => (
    <RadioWrapper>
        <input type='radio' id={id} value={value} name={name} checked={checked} onChange={onChange} />
        <label htmlFor={id}>
            <Icon><Check/></Icon>
            {children}
        </label>
    </RadioWrapper>
);

export default Radio;
export {RadioWrapper};

