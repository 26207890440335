import { createGlobalStyle } from "styled-components";
import OpenSansBold from "../../assets/OpenSans/OpenSans-Bold.ttf";
import OpenSansSemiBold from "../../assets/OpenSans/OpenSans-SemiBold.ttf";
import OpenSansRegular from "../../assets/OpenSans/OpenSans-Regular.ttf";

const FontStyles = createGlobalStyle`
    @font-face {
        font-family: 'OpenSans';
        src: url(${OpenSansBold});
        font-weight: 700;
    }
    @font-face {
        font-family: 'OpenSans';
        src: url(${OpenSansSemiBold});
        font-weight: 400;
    }
    @font-face {
        font-family: 'OpenSans';
        src: url(${OpenSansRegular});
        font-weight: 100;
    }

    body {
        font-family: 'OpenSans';
        margin: 0;
        height: 100%;
        background: #F3F4F8;
        @media only screen and (max-width: 700px) {
            .axis-title {
                transform: rotate(48deg);
                bottom: 0;
                left: 0;
                margin-top: 14px;
            }
            .subsidy-group,
            .progress-bar,
            .bar{
                height: 20px!important;
            }
        }
    }
    *,
    *::before,
    *::after {
        box-sizing: border-box; // 1
    }
`;



export default FontStyles;