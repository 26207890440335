const SpaceVariables = () =>  `
    --space-xsm:                      4px;
    --space-sm:                      8px;
    --space-rl:                      12px;
    --space-md:                      20px;
    --space-lg:                      28px;
    --space-xl:                      36px;
    --space-xxl:                     44px;
    --space-xxxl:                    120px;
`;

export default SpaceVariables;