import {useState, useEffect} from 'react';
import axios from 'axios';

export function useTenant(accessGuid) {
    const [tenant, setTenant] = useState(null);

    useEffect(() => {
        if (!accessGuid) return;

        axios.get(`/api/subsidiewijzer/tenants/${accessGuid}`)
            .then(response => {
                setTenant(response.data);
            });
    }, [accessGuid]);

    return tenant;
}

export function useDefaultTenant() {
    const [tenant, setTenant] = useState(null);

    useEffect(() => {
        axios.get(`/api/subsidiewijzer/tenants/default`)
            .then(response => {
                setTenant(response.data);
            });
    }, []);

    return tenant;
}
