import _ from 'lodash';

class QuestionsHelper {
    constructor(allQuestions, givenAnswers, currentQuestion) {
        this.allQuestions = allQuestions;
        this.givenAnswers = givenAnswers || {};

        this.currentQuestion = currentQuestion || _.first(this.activeQuestions);
    }

    get currentTags() {
        return this.tagsForAnswers(this.givenAnswers);
    }

    tagsForAnswers(answers) {
        return _.flatMap(answers, (answerIds, questionId) => {
            const question = _.find(this.allQuestions, {swQuestionId: +questionId});
            if(!question) return [];

            const answers = _.filter(question.answers, a => _.includes(answerIds, a.swAnswerId));

            return _.flatMap(answers, 'tags');
        });
    }

    get activeQuestions() {
        var currentTags = _.map(this.currentTags, 'swTagId'); 
        return this.activeQuestionsForTags(currentTags);
    }

    activeQuestionsForTags(tagIds) {
        return _.filter(this.allQuestions, question => {
            const mandatoryTags = _.map(
                _.filter(question.tags, {condition: 0}),
                'swTagId'
            );

            const forbiddenTags  = _.map(
                _.filter(question.tags, {condition: 1}),
                'swTagId'
            );

            return _.every(mandatoryTags, t => _.includes(tagIds, t))
                && _.every(forbiddenTags, t => !_.includes(tagIds, t)); 
        });
    }

    // returs whether you can click the next button, given the currently selected answers
    get canNext() {
        const active = this.activeQuestions;
        const currentIx = _.indexOf(active, this.currentQuestion);
       
        return currentIx < active.length - 1;
    }

    get currentQuestionNumber() {
        const currentIx = _.indexOf(this.activeQuestions, this.currentQuestion);
        return currentIx + 1;
    }

    next() {
        const active = this.activeQuestions;
        const currentIx = _.indexOf(active, this.currentQuestion);
       
        if(currentIx === active.length - 1) return this;

        var newCurrentQuestion = active[currentIx + 1];

        this.setQuestion(newCurrentQuestion.swQuestionId);
        this.setNavigatables();
    }

    get canBack() {
        const currentIx = _.indexOf(this.activeQuestions, this.currentQuestion);

        return currentIx !== 0;
    }

    back() {
        const active = this.activeQuestions;
        const currentIx = _.indexOf(active, this.currentQuestion);

        if(currentIx === 0) return this;

        var newCurrentQuestion = active[currentIx - 1];

        this.setQuestion(newCurrentQuestion.swQuestionId);
    }

    setQuestion(swQuestionId) {
        if (!swQuestionId){
            this.currentQuestion = null;
            return;
        }

        var question = _.find(this.activeQuestions, x => x.swQuestionId === swQuestionId);
        if (!question)
            return;

        this.currentQuestion = question;
    }

    setAnswer(answers) {
        this.givenAnswers[this.currentQuestion.swQuestionId] = answers;
        this.setNavigatables();
    }

    setNavigatables() {
        _.forEach(this.allQuestions, q => {
            q.canNavigateTo = this.activeQuestions.includes(q) && q.order <= this.currentQuestion.order
        });
    }

    get currentAnswers() {
        return _.get(this.givenAnswers, this.currentQuestion?.swQuestionId, []);
    }

    get done() {
        return !this.canNext && _.every(this.activeQuestions, q => questionIsAnswered(q, this.givenAnswers))
    }
}

export default QuestionsHelper;

export function questionIsAnswered(question, givenAnswers) {
    var answeredQuestionIds = _.map(_.keys(givenAnswers), x => parseInt(x));
    return answeredQuestionIds.includes(question.swQuestionId) && _.get(givenAnswers, question.swQuestionId, []).length >= 1;
}
