 const ColorVariables = () =>  `
    --primary:                      #000;
    --caution:                       #EBBF55;
    --warning:                       #D3E736;
    --blank:                        #FFF;
    --border-gray:                  #EFEFEF;
    --card-border-gray:             #C4C4C4;
    --form-broder:                  #D1D1D1;
    --txt-gray:                     #939AAA;
    --danger:                       #EB5555;

    --txt-chart:                    #8793B1;
    --alpha:                        #007bff;
    --delta:                        #28a745;
`;


export default ColorVariables;