import styled from 'styled-components';
const iconSetup = (type) => {
    if (!type) return 'width: 16px; ';

    return `
            width: var(--icon-size-${type}); 
        `;
};

const Icon = styled.span`
    --icon-size-sm: 12px;
    --icon-size-md: 20px;
    --icon-size-lg: 32px;
    --icon-size-xl: 44px;
    svg {
        ${({type}) => iconSetup(type)};
    }
`;

export default Icon;
