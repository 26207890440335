import styled from "styled-components";
import ColorVariables from './shared-components/variables/ColorVariables';
import ShapeVariables from './shared-components/variables/ShapeVariables';
import SpaceVariables from './shared-components/variables/SpaceVariables';
import QuestionnairePage from './modules/questions/QuestionnairePage.js';
import {HashRouter as Router, Routes, Route} from 'react-router-dom';
import DefaultQuestionnaireRedirect from './DefaultQuestionnaireRedirect';

const SassVars = styled.div`
    ${ColorVariables}
    ${ShapeVariables}
    ${SpaceVariables}
`;

function App() {
    return (
        <div className="App">
            <SassVars>
                <Router>
                    <Routes>
                        <Route exact path="/questionnaire/:accessGuid" element={<QuestionnairePage />} />
                        <Route exact path="/" element={<DefaultQuestionnaireRedirect />} />
                    </Routes>
                </Router>
            </SassVars>
        </div>
    );
}

export default App;
