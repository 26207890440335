import React from 'react';
import MainContent from '../../shared-components/grid/MainContent/MainContent';
import { Title, Description} from '../../shared-components/elements/Txt/Txt';
import { Question } from '../../shared-components/elements/Txt/Question';
import Radio from '../../shared-components/form/form-control/Radio';
import Checkbox from '../../shared-components/form/form-control/Checkbox';
import FormGroup from '../../shared-components/form/FormGroup';
import parse from 'html-react-parser';
import _ from 'lodash';

export default function QuestionnaireContent({currentQuestion, currentQuestionNumber, answers, setAnswers}) {
    const multipleChoice = currentQuestion.multipleChoice;

    const change = (ev) => {
        if(multipleChoice) {
            if(ev.target.checked) setAnswers(_.union(answers, [+ev.target.value]));
            if(!ev.target.checked) setAnswers(_.without(answers, +ev.target.value));
        } else {
            setAnswers([+ev.target.value]);
        }
    }

    return (
        <MainContent>
            <Title>{currentQuestionNumber}. {currentQuestion.title}</Title>
            <Question>{currentQuestion.question}</Question>
            <Description>{parse(_.isNull(currentQuestion.description) ? '' : currentQuestion.description)}</Description>

            {multipleChoice ? (
                <FormGroup>
                    {currentQuestion.answers.map(answer => (
                        <Checkbox key={answer.swAnswerId} value={answer.swAnswerId} id={answer.swAnswerId} name="checkbox" checked={_.includes(answers, answer.swAnswerId)} onChange={change}>
                            {answer.text}
                        </Checkbox>
                    ))}
                </FormGroup>
            ) : (
                <FormGroup>
                    {currentQuestion.answers.map(answer => (
                        <Radio key={answer.swAnswerId} value={answer.swAnswerId} id={answer.swAnswerId} name="radio" checked={_.includes(answers, answer.swAnswerId)} onChange={change}>
                            {answer.text}
                        </Radio>
                    ))}
                </FormGroup>
            )}
        </MainContent>
    );
}

